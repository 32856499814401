<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
  getMbInfo,
  getExamInfo,
  userSpecial,
  getExclusive,
  specialApply, getPosition,
} from "@/api/examination";
import { format } from "@/libs/common";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "报名协议",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "报名协议",
          active: true,
        },
      ],
      uesrInfo: {},
      ksbmbh: "", //考试编号
      ExamInfo: {},
      MbInfo: "",
      Countdown: 3,
      Index: 0,
      mbhList: [],
      kscnshList: [],
      order: 1,
      sfty: null,
    };
  },
  watch: {
    MbInfo: {
      handler(data) {
        let text = this.MbInfo.mbnr;
        text = text.replace(/{XM}/g, this.uesrInfo.xm);
        text = text.replace(/{ZJLX}/g, this.uesrInfo.sfzjlxmc);
        text = text.replace(/{SFZH}/g, this.uesrInfo.sfzjh);
        text = text.replace(/{RQ}/g, format(new Date(), "yyyy年MM月dd日"));
        this.MbInfo.mbnr = text;
      },
    },
  },
  methods: {
    next(sfty) {
      this.sfty = sfty;
      if (this.ExamInfo.sfqyzymdk) {
        this.getSpecial();
      } else {
        this.$router.replace({
          path: "/examIndex",
          query: {
            ksbmbh: this.ksbmbh,
            sfty: sfty,
          },
        });
      }
    },
    agreement() {
      this.Countdown = 3;
      if (this.mbhList[this.Index]) {
        this.resetCode1();
      } else {
        if (this.kscnshList.length != 0) {
          this.order = 2;
          this.Index = 0;
          this.resetCode2();
        } else {
          if (this.ExamInfo.sfqyzymdk) {
            this.getSpecial();
          } else {
            this.$router.replace({
              path: "/examIndex",
              query: {
                ksbmbh: this.ksbmbh,
              },
            });
          }
        }
      }
    },
    //查询特殊库
    getSpecial() {
      getExclusive(this.ExamInfo.ksbmbh).then((res) => {
        if (res.status) {
          let data = res.data[0];
          if (data) {
            if (!this.ExamInfo.bkdwbt || !this.ExamInfo.bkzwbt) {
              //导入信息
              let obj = {
                ksbmbh: this.ExamInfo.ksbmbh,
              };
              if (this.sfty != null) {
                obj.sfty = this.sfty;
              } else {
                obj.sfty = true;
              }
              specialApply(obj).then((res) => {
                if (res.status) {
                  this.$router.replace({
                    path: "/enrollForm",
                    query: {
                      ksbmbh: this.ksbmbh,
                      bmh: res.data.bmh,
                    },
                  });
                }
              });
            } else {
              this.$router.replace({
                path: "/examIndex",
                query: {
                  ksbmbh: this.ksbmbh,
                  isSpecial: true,
                  sfty: this.sfty != null ? this.sfty : "",
                },
              });
            }
          } else {
            this.$router.replace({
              path: "/examIndex",
              query: {
                ksbmbh: this.ksbmbh,
                sfty: this.sfty != null ? this.sfty : "",
              },
            });
          }
        }
      });
    },
    //倒计时
    resetCode1() {
      var timer = null;
      var _this = this;
      getMbInfo(this.mbhList[this.Index]).then((res) => {
        if (res.status) {
          _this.MbInfo = res.data || "";
          timer = setInterval(function () {
            _this.Countdown -= 1;

            if (_this.Countdown > 0) {
            } else {
              clearInterval(timer);
              _this.Index += 1;
            }
          }, 1000);
        }
      });
    },
    resetCode2() {
      var timer = null;
      var _this = this;
      getMbInfo(this.kscnshList[this.Index]).then((res) => {
        if (res.status) {
          _this.MbInfo = res.data || "";
          timer = setInterval(function () {
            _this.Countdown -= 1;

            if (_this.Countdown > 0) {
            } else {
              clearInterval(timer);
              _this.Index += 1;
              if (_this.kscnshList[_this.Index]) {
                _this.Countdown = 10;

                _this.resetCode2();
              }
            }
          }, 1000);
        }
      });
    },

    //获取岗位
    getZwList() {
      getPosition(this.ksbmbh).then((res) => {
        // eslint-disable-next-line no-empty
        if(res) {}
      });
    },
  },
  created() {
    this.uesrInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    this.ksbmbh = this.$route.query.ksbmbh;
    getExamInfo(this.ksbmbh).then((res) => {
      if (res.status) {
        this.ExamInfo = res.data;
        if (this.ExamInfo.ksxzh) {
          this.mbhList = this.ExamInfo.ksxzh
            ? this.ExamInfo.ksxzh.split(",")
            : "";
        }
        // 避免出现 “职位码错误,请刷新再试。”
        if(this.ExamInfo.kmxzfs == 2) {
          this.getZwList()
        }

        if (this.ExamInfo.sfgzcn) {
          this.kscnshList = this.ExamInfo.kscnsh
            ? this.ExamInfo.kscnsh.split(",")
            : "";
        }
        if (this.ExamInfo.ksxzh) {
          this.resetCode1();
        } else if (this.ExamInfo.sfgzcn) {
          this.resetCode2();
        } else {
          if (this.ExamInfo.sfqyzymdk) {
            this.getSpecial();
          } else {
            this.$router.replace({
              path: "/examIndex",
              query: {
                ksbmbh: this.ksbmbh,
              },
            });
          }
        }
      }
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2">正在报名：</span>
              <span class="blue-font">{{ ExamInfo.ksmc }}</span>
            </h4>
            <div class="agreement-box">
              <!-- <div class="agree-top">
                <div class="agree-title">{{ MbInfo.mbmc }}</div>
              </div> -->
              <div class="agree-main mt-2">
                <div class="p-3 mb-3">
                  <div v-if="MbInfo.mbnr" v-html="MbInfo.mbnr"></div>
                </div>
              </div>
              <div class="agree-btn mt-2" v-if="order == 1">
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="agreement"
                  :disabled="Countdown != 0"
                >
                  我已知晓并同意{{ Countdown != 0 ? Countdown + "s" : "" }}
                </button>
              </div>
              <div class="agree-btn mt-2" v-if="order == 2">
                <button
                  type="button"
                  class="btn btn-danger mr-5"
                  @click="next(true)"
                  :disabled="Countdown != 0"
                >
                  采用告知承诺制{{ Countdown != 0 ? Countdown + "s" : "" }}
                </button>
                <button
                  type="button"
                  class="btn btn-danger bg-secondary"
                  @click="next(false)"
                  style="border: unset"
                  :disabled="Countdown != 0"
                >
                  不采用告知承诺制{{ Countdown != 0 ? Countdown + "s" : "" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
